// src/components/SharedFeed.js

import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './SharedFeed.css'; // Ensure this CSS file exists and is properly styled
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import { WebSocketContext } from '../context/WebSocketContext'; // Import WebSocketContext
import { apiFetch } from '../utils/api'; // Import the apiFetch utility
import { toast } from 'react-toastify'; // Optional: For user-friendly notifications
import ImageWithFallback from './ImageWithFallback'; // Import ImageWithFallback

// Helper function to build a final image URL with cache busting.
function buildImageUrl(url, id, cacheBustValues, defaultUrl) {
  const baseUrl = url || defaultUrl;
  const cacheBust = cacheBustValues[String(id)] || '';
  const separator = baseUrl.includes('?') ? '&' : '?';
  return `${baseUrl}${cacheBust ? `${separator}cacheBust=${cacheBust}` : ''}`;
}

function SharedFeed() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext); // Authenticated user
  const { cacheBustValues } = useContext(WebSocketContext); // Cache bust values

  const [feedItems, setFeedItems] = useState([]);
  const [userInfo, setUserInfo] = useState(null); // Target user's info
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // Compute a display name from the returned fields.
  const displayName =
    (userInfo && userInfo.name) ||
    (userInfo && `${userInfo.firstName || ''} ${userInfo.lastName || ''}`.trim()) ||
    '';

  // Fetch the target user's information.
  const fetchUserInfo = useCallback(async () => {
    if (!userId) {
      setError('Invalid user ID');
      setLoading(false);
      return;
    }
  
    try {
      // If viewing your own feed, use "user-info"; otherwise, use "accounts/{userId}"
      const endpoint = String(user?.id) === userId ? 'user-info' : `accounts/${userId}`;
      const data = await apiFetch(endpoint, { method: 'GET' });
      setUserInfo(data);
    } catch (err) {
      console.error('Error fetching user info:', err.message);
      setError(err.message);
      setUserInfo(null);
      if (err.message === 'Unauthorized') {
        navigate('/login');
      }
    }
  }, [userId, navigate, user]);

  // Fetch shared feed items.
  const fetchFeedItems = useCallback(async () => {
    try {
      const data = await apiFetch(`shared-feed/${userId}`, { method: 'GET' });
      setFeedItems(data.feedItems);
    } catch (err) {
      console.error('Error fetching shared feed:', err.message);
      setError(err.message);
      setFeedItems([]);
      if (err.message === 'Unauthorized') {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  }, [userId, navigate]);

  useEffect(() => {
    // Fetch user info and feed items concurrently.
    const fetchData = async () => {
      await Promise.all([fetchUserInfo(), fetchFeedItems()]);
    };
    fetchData();
  }, [fetchUserInfo, fetchFeedItems]);

  // Handler for liking a feed item.
  const handleLike = useCallback(
    async (feedItemId) => {
      try {
        await apiFetch(`shared-feed/${userId}/like/${feedItemId}`, { method: 'POST' });
        setFeedItems((prevItems) =>
          prevItems.map((item) =>
            item.id === feedItemId
              ? { ...item, likesCount: item.likesCount + 1, liked: true }
              : item
          )
        );
        toast.success('Liked successfully!');
      } catch (error) {
        console.error('Error liking feed item:', error.message);
        toast.error(`Error: ${error.message}`);
      }
    },
    [userId]
  );

  // Handler for commenting on a feed item.
  const handleComment = useCallback((feedItemId) => {
    toast.info('Comment functionality to be implemented.');
  }, []);

  // Handler for deleting a feed item.
  const handleDelete = useCallback(
    async (feedItemId) => {
      const confirmDelete = window.confirm('Are you sure you want to delete this post?');
      if (!confirmDelete) return;
      try {
        await apiFetch(`posts/${feedItemId}`, { method: 'DELETE' });
        setFeedItems((prevItems) => prevItems.filter((item) => item.id !== feedItemId));
        toast.success('Post deleted successfully!');
      } catch (error) {
        console.error('Error deleting feed item:', error.message);
        toast.error(`Error: ${error.message}`);
      }
    },
    []
  );

  useEffect(() => {
    console.log('SharedFeed: cacheBustValues updated:', cacheBustValues);
  }, [cacheBustValues]);

  // Determine if the logged-in user is viewing their own feed.
  const isOwnFeed = user && String(user.id) === userId;

  if (loading) {
    return (
      <div className="shared-feed-page">
        <p className="loading">Loading feed...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="shared-feed-page">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  if (!userInfo) {
    return (
      <div className="shared-feed-page">
        <p>No user information available.</p>
      </div>
    );
  }

  return (
    <div className="shared-feed-page">
      <div className="header">
        <h1>{displayName}'s Feed</h1>
      </div>

      {/* Display the target user's avatar and basic info */}
      <div className="user-profile">
        <ImageWithFallback
          src={buildImageUrl(userInfo.avatarUrl, userInfo.id, cacheBustValues, '/default-avatar.jpg')}
          fallbackSrc="/default-avatar.jpg"
          alt={`${displayName}'s avatar`}
          className="avatar"
        />
        <div className="user-details">
          <h2>{displayName}</h2>
          <p>{userInfo.email}</p>
          <p>{userInfo.bio}</p>
        </div>
      </div>

      {/* Feed Items */}
      <div className="feed-items">
        {feedItems.length > 0 ? (
          feedItems.map((item) => (
            <div key={item.id} className="feed-item">
              <div className="feed-header">
                <ImageWithFallback
                  src={buildImageUrl(item.authorAvatarUrl, item.authorId, cacheBustValues, '/default-avatar.jpg')}
                  fallbackSrc="/default-avatar.jpg"
                  alt={`${item.authorName}'s avatar`}
                  className="avatar"
                />
                <div className="author-info">
                  <h3>{item.authorName}</h3>
                  <p className="timestamp">{new Date(item.timestamp).toLocaleString()}</p>
                </div>
                {isOwnFeed && item.authorId === user.id && (
                  <button
                    onClick={() => handleDelete(item.id)}
                    className="delete-button"
                    aria-label="Delete this post"
                  >
                    Delete
                  </button>
                )}
              </div>
              <div className="feed-content">
                <p>{item.content}</p>
                {item.imageUrl && (
                  <ImageWithFallback
                    src={buildImageUrl(item.imageUrl, item.authorId, cacheBustValues, '/default-avatar.jpg')}
                    fallbackSrc="/default-avatar.jpg"
                    alt="Feed visual content"
                    className="feed-image"
                  />
                )}
              </div>
              <div className="feed-actions">
                <button onClick={() => handleLike(item.id)}>
                  Like ({item.likesCount})
                </button>
                <button onClick={() => handleComment(item.id)}>
                  Comment ({item.commentsCount})
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No feed items to display.</p>
        )}
      </div>
    </div>
  );
}

export default SharedFeed;
