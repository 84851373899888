import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiFetch } from '../utils/api'; 
import MindMap from './mindmap/MindMap';
import { WebSocketContext } from '../context/WebSocketContext';

function MindMapPage() {
  const navigate = useNavigate();
  const { user, loading: authLoading } = useContext(AuthContext);
  const { blinkingUsers, removeBlinkingUser } = useContext(WebSocketContext);

  // Track accepted connections
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    console.log('MindMapPage: useEffect triggered');
    console.log('authLoading:', authLoading, ' user:', user);

    if (authLoading) {
      console.log('Auth still loading, not fetching connections yet.');
      return;
    }
    if (!user) {
      console.warn('User is not logged in. Redirecting to /login');
      navigate('/login');
      return;
    }

    // Fetch accepted connections from the same endpoint as AcceptedConnections
    async function fetchConnections() {
      console.log('fetchConnections called...');
      try {
        const data = await apiFetch('connections/accepted', { method: 'GET' });
        console.log('Raw data from /connections/accepted:', data);

        /**
         * data.acceptedConnections might hold multiple connections.
         * We also want avatarUrl (and possibly email) for MindMap.
         */
        const mapped = (data.acceptedConnections || []).map((conn) => ({
          id: conn.id,
          name: conn.name,
          email: conn.email,         // optional if you want the email
          avatarUrl: conn.avatarUrl, // <-- critical for displaying each user's avatar
        }));

        console.log('Mapped accepted connections:', mapped);
        setConnectedUsers(mapped);

        // Log right after setting state
        console.log(
          'State "connectedUsers" has been updated with mapped array of length:',
          mapped.length
        );
      } catch (err) {
        console.error('Error fetching accepted connections:', err);
        setError(err.message);
        if (err.message === 'Unauthorized') {
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    }

    fetchConnections();
  }, [user, authLoading, navigate]);

  const handleMarkUserAsRead = async (senderId) => {
    console.log(`MindMapPage: Marking user ${senderId} messages as read`);
    // Possibly call your messages read endpoint, e.g.:
    // await apiFetch(`messages/${messageId}/read`, { method: 'PUT' });
    // For now, just remove them from blinking set:
    removeBlinkingUser(senderId);
  };

  console.log('MindMapPage render: connectedUsers length =', connectedUsers.length);

  if (loading) {
    return <p>Loading Mind Map...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>Error: {error}</p>;
  }

  if (connectedUsers.length === 0) {
    return <p>You have no accepted connections yet.</p>;
  }

  // Build the central user object 
  const centralUser = {
    id: user?.id || 1,
    name: user?.name || user?.firstName || 'Me',
    avatarUrl: user?.avatarUrl, // if your user object has avatarUrl
  };

  console.log(
    'MindMapPage: Rendering MindMap with centralUser =',
    centralUser,
    'connectedUsers =',
    connectedUsers
  );

  return (
    <div>
      <h2>My MindMap of Accepted Connections</h2>
      <MindMap
        centralUser={centralUser}
        connectedUsers={connectedUsers}
        blinkingUsers={blinkingUsers}
        onMessageRead={handleMarkUserAsRead}
      />
    </div>
  );
}

export default MindMapPage;
