import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { AuthContext } from './AuthContext';
import { apiFetch } from '../utils/api';
import { toast } from 'react-toastify';

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
  const wsRef = useRef(null);
  const messageQueueRef = useRef([]);

  // States managed by WebSocketContext
  const [notifications, setNotifications] = useState([]);
  const [activities, setActivities] = useState([]);
  const [feedItems, setFeedItems] = useState([]);
  const [liveFeedItems, setLiveFeedItems] = useState([]);
  const [cacheBustValues, setCacheBustValues] = useState({});
  const [error, setError] = useState(null);

  // Auth context
  const { user, setUser } = useContext(AuthContext);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [blinkingUsers, setBlinkingUsers] = useState(new Set());

  /**
   * Handles all incoming WebSocket messages, dispatching based on 'data.type'.
   */
  const handleWebSocketMessage = useCallback(
    (data) => {
      switch (data.type) {
        case 'direct_message':
          setNotifications((prev) => [
            {
              id: data.payload.id,
              message: `${data.payload.sender} sent you a message: ${data.payload.content}`,
              timestamp: new Date(data.payload.timestamp).toLocaleString(),
              read: false,
            },
            ...prev,
          ]);
          // Optionally remove or keep the toast:
          toast.info(`New Message from ${data.payload.sender}`);
          break;

        case 'new_message': {
          // existing “new_message” logic
          const msg = data.payload;
          setNotifications((prev) => [
            {
              id: msg.id || Date.now(),
              message: `${msg.senderEmail} says: ${msg.content}`,
              timestamp: new Date(msg.timestamp).toLocaleString(),
              read: false,
            },
            ...prev,
          ]);
          // Possibly remove the toast if you want the same silent approach:
          toast.info(`New message from ${msg.senderEmail}`);

          if (msg.senderId) {
            setBlinkingUsers((prev) => new Set(prev).add(msg.senderId));
          }
          break;
        }

        /**
         * Connection Request -> add to notifications, no toast
         */
        case 'connection_request': {
          const payload = data.payload;
          const requesterEmail = payload.requesterEmail;

          // Add an item to the notifications array
          setNotifications((prev) => [
            {
              id: Date.now(), // or a unique ID from the payload
              message: `New connection request from ${requesterEmail}`,
              timestamp: new Date().toLocaleString(),
              read: false,
            },
            ...prev,
          ]);

          // NO toast here - so it displays the same as other messages in your UI
          break;
        }

        /**
         * Connection Removed -> add to notifications, no toast
         */
        case 'connection_removed': {
          const payload = data.payload;
          const removerEmail = payload.removerEmail;

          // Append to notifications
          setNotifications((prev) => [
            {
              id: Date.now(),
              message: `Your connection with ${removerEmail} was removed`,
              timestamp: new Date().toLocaleString(),
              read: false,
            },
            ...prev,
          ]);

          // NO toast call
          break;
        }

        case 'broadcast':
          setFeedItems((prevFeed) => [data.payload, ...prevFeed]);
          toast.info(`Broadcast: ${data.payload.content}`);
          break;

        case 'activity_update':
          setActivities((prevActivities) => [data.payload, ...prevActivities]);
          break;

        case 'new_comment': {
          const newComment = data.payload;
          setNotifications((prev) => [
            {
              id: newComment.id,
              message: `${newComment.userEmail} commented: ${newComment.content}`,
              timestamp: new Date(newComment.timestamp).toLocaleString(),
              read: false,
            },
            ...prev,
          ]);
          toast.info(`New Comment: ${newComment.userEmail} commented on your post.`);
          break;
        }

        case 'new_notification':
          setNotifications((prev) => [
            {
              id: data.payload.id,
              title: data.payload.title,
              message: data.payload.message,
              timestamp: new Date(data.payload.timestamp).toLocaleString(),
              read: data.payload.read,
            },
            ...prev,
          ]);
          toast.info(`New Notification: ${data.payload.title}`);
          break;

        case 'update_user':
          if (data.payload && data.payload.user) {
            setUser(data.payload.user);
          }
          break;

        case 'avatar_update': {
          const { userId, cacheBustValue } = data.payload;
          setCacheBustValues((prevValues) => ({
            ...prevValues,
            [userId]: cacheBustValue,
          }));
          break;
        }

        default:
          console.warn('WebSocketContext: Unhandled message type:', data.type);
      }
    },
    [setUser, setNotifications, setActivities, setFeedItems, toast]
  );

  /**
   * Optionally fetch initial data from your API
   */
  const fetchInitialData = useCallback(async () => {
    try {
      const initialNotifications = await apiFetch('notifications', { method: 'GET' });
      setNotifications(
        initialNotifications.map((notif) => ({
          id: notif.id,
          title: notif.title,
          message: notif.message,
          timestamp: new Date(notif.timestamp).toLocaleString(),
          read: notif.read,
        }))
      );

      const initialActivities = await apiFetch('activities', { method: 'GET' });
      setActivities(initialActivities);

      const initialFeed = await apiFetch('feed', { method: 'GET' });
      setFeedItems(initialFeed);

      const initialLiveFeed = await apiFetch('live-feed', { method: 'GET' });
      setLiveFeedItems(initialLiveFeed);
    } catch (err) {
      console.error('WebSocketContext: Error fetching initial data:', err.message);
      toast.error('Failed to load initial data.');
    }
  }, [toast]);

  // Reconnection logic
  const reconnectAttempts = useRef(0);
  const reconnectTimer = useRef(null);
  const maxReconnectAttempts = 10;
  const baseReconnectInterval = 5000;

  /**
   * Establishes the WebSocket connection with exponential backoff
   */
  const establishWebSocketConnection = useCallback(async () => {
    if (!currentUserId) {
      return;
    }

    try {
      const socketUrl = `${websocketUrl}?userId=${currentUserId}`;
      const socket = new WebSocket(socketUrl);

      wsRef.current = socket;

      socket.onopen = () => {
        reconnectAttempts.current = 0;

        // Send queued messages if any
        if (messageQueueRef.current.length > 0) {
          messageQueueRef.current.forEach((queuedMessage) => {
            socket.send(JSON.stringify(queuedMessage));
          });
          messageQueueRef.current = [];
        }
      };

      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          handleWebSocketMessage(data);
        } catch (error) {
          console.error('WebSocketContext: Error parsing WebSocket message:', error);
        }
      };

      socket.onerror = (errorEvent) => {
        console.error('WebSocketContext: WebSocket encountered an error:', errorEvent);
      };

      socket.onclose = (event) => {
        console.warn(`WebSocketContext: WebSocket closed: ${event.reason}`);

        if (reconnectAttempts.current < maxReconnectAttempts) {
          const reconnectInterval = baseReconnectInterval * Math.pow(2, reconnectAttempts.current);
          reconnectAttempts.current += 1;
          reconnectTimer.current = setTimeout(() => {
            establishWebSocketConnection();
          }, reconnectInterval);
        } else {
          console.error('WebSocketContext: Max reconnection attempts reached. Could not reconnect.');
          setError('Could not reconnect to WebSocket.');
          toast.error('Unable to maintain real-time connection.');
        }
      };
    } catch (error) {
      console.error('WebSocketContext: Error establishing WebSocket connection:', error);
      setError('Failed to establish WebSocket connection.');
      toast.error('Failed to establish real-time connection.');
    }
  }, [currentUserId, handleWebSocketMessage, websocketUrl, toast]);

  /**
   * Once user is set (meaning we have a user ID), fetch data and open WebSocket
   */
  useEffect(() => {
    const initialize = async () => {
      if (currentUserId) {
        await fetchInitialData();
        establishWebSocketConnection();
      }
    };
    initialize();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      if (reconnectTimer.current) {
        clearTimeout(reconnectTimer.current);
      }
    };
  }, [currentUserId, establishWebSocketConnection, fetchInitialData]);

  /**
   * Sends a JSON message to the server (or queue if WS not ready)
   */
  const sendMessage = useCallback((message) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(message));
    } else {
      messageQueueRef.current.push(message);
    }
  }, []);

  /**
   * Optionally: helper function to notify server about an avatar update
   */
  const sendAvatarUpdate = useCallback(
    (cacheBustValue) => {
      // Ensure the user is logged in
      if (currentUserId) {
        sendMessage({
          type: 'avatar_update',
          cacheBustValue: cacheBustValue,
        });
      }
    },
    [currentUserId, sendMessage]
  );

  /**
   * Update currentUserId based on AuthContext user
   */
  useEffect(() => {
    if (user && user.id) {
      setCurrentUserId(user.id);
    } else {
      setCurrentUserId(null);
    }
  }, [user]);

  const removeBlinkingUser = useCallback((userId) => {
    setBlinkingUsers((prev) => {
      const newSet = new Set(prev);
    newSet.delete(userId);
    return newSet;
  });
  }, []);

  /**
   * Provide these values to children
   */
  const contextValue = useMemo(
    () => ({
      wsRef,
      sendMessage,
      sendAvatarUpdate,
      notifications,
      setNotifications,
      activities,
      setActivities,
      feedItems,
      setFeedItems,
      liveFeedItems,
      setLiveFeedItems,
      currentUserId,
      setCurrentUserId,
      error,
      cacheBustValues,
      blinkingUsers,
      removeBlinkingUser,
    }),
    [
      notifications,
      activities,
      feedItems,
      liveFeedItems,
      currentUserId,
      error,
      cacheBustValues,
      sendMessage,
      sendAvatarUpdate,
      blinkingUsers,
      removeBlinkingUser,
    ]
  );

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
