// src/components/MindMap.js

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './MindMap.css';
import { ThemeContext } from '../../context/ThemeContext';
import { WebSocketContext } from '../../context/WebSocketContext';

function MindMap({ centralUser, connectedUsers, blinkingUsers = new Set() }) {
  const { theme } = useContext(ThemeContext);
  const { cacheBustValues } = useContext(WebSocketContext);
  const navigate = useNavigate();

  // When a grid item (or avatar) is clicked, navigate to the SharedFeed page for that user
  const handleUserClick = (userId) => {
    navigate(`/shared-feed/${userId}`);
  };

  const buildAvatarUrl = (userId, baseUrl) => {
    const cacheBustValue = cacheBustValues[String(userId)] || '';
    const actualBase = baseUrl || '/default-avatar.jpg';
    const separator = actualBase.includes('?') ? '&' : '?';
    return cacheBustValue
      ? `${actualBase}${separator}cacheBust=${cacheBustValue}`
      : actualBase;
  };

  return (
    <div className={`grid-container ${theme}-grid`}>
      {/* Leader / Central User Card (not clickable) */}
      <div className="leader-card">
        {centralUser.avatarUrl ? (
          <img
            src={buildAvatarUrl(centralUser.id, centralUser.avatarUrl)}
            alt={centralUser.name}
            className="avatar leader-avatar"
          />
        ) : (
          <div className="avatar-placeholder leader-placeholder">
            {centralUser.name.charAt(0)}
          </div>
        )}
        <div className="user-label">{centralUser.name}</div>
      </div>

      {/* Grid of Connected Users */}
      <div className="users-grid">
        {connectedUsers.map((user) => {
          const isBlinking = blinkingUsers.has(user.id);
          return (
            <div
              key={user.id}
              className={`grid-item ${isBlinking ? 'blinking' : ''}`}
              onClick={() => handleUserClick(user.id)}
            >
              {user.avatarUrl ? (
                <img
                  src={buildAvatarUrl(user.id, user.avatarUrl)}
                  alt={user.name}
                  className="avatar"
                />
              ) : (
                <div className="avatar-placeholder">
                  {user.name.charAt(0)}
                </div>
              )}
              <div className="user-label">{user.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

MindMap.propTypes = {
  centralUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
  }).isRequired,
  connectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
    })
  ).isRequired,
  blinkingUsers: PropTypes.instanceOf(Set),
};

export default MindMap;
