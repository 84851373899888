// src/context/AuthContext.js

import React, { createContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiFetch } from '../utils/api'; // Make sure this has credentials: 'include' if using cookies

export const AuthContext = createContext();

// Utility function to normalize user data
const normalizeUser = (data) => {
  const userData = data.user || data;
  if (userData.id && userData.email) {
    return {
      id: userData.id,
      email: userData.email,
      isEmailVerified: userData.isEmailVerified,
      avatarUrl: userData.avatarUrl,
      firstName: userData.firstName,
      lastName: userData.lastName,
      bio: userData.bio,
    };
  } else {
    return null;
  }
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();

  // Check auth status & fetch user info
  const checkAuth = async () => {
    try {
      const data = await apiFetch('user-info', { method: 'GET' });
      console.log('AuthContext: Received user data:', data);
      const normalizedUser = normalizeUser(data);
      console.log('AuthContext: Normalized user data:', normalizedUser);
      setUser(normalizedUser);
    } catch (error) {
      // Gracefully handle a 401
      // (Depending on your apiFetch, error.status might be undefined. 
      //  If so, check error.message.includes('401') instead)
      if (error.status === 401 || error.errorCode === 'UNAUTHORIZED') {
        setUser(null);
      } else {
        console.error('AuthContext: Unexpected error fetching user info:', error.message);
        setUser(null);
      }
    } finally {
      setLoading(false);
    }
  };

  // On mount, check authentication
  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Log in
  const login = async (credentials) => {
    try {
      const data = await apiFetch('login', {
        method: 'POST',
        body: credentials,
      });
      // Now that login is successful, re-check auth
      await checkAuth();
      navigate('/dashboard');
      return { success: true };
    } catch (error) {
      console.error('AuthContext: Login failed:', error.message);
      if (error.errorCode === 'UNVERIFIED_EMAIL') {
        return { success: false, error: 'unverified_email' };
      }
      return { success: false, error: error.message };
    }
  };

  // Sign up
  const signup = async (formData) => {
    try {
      const data = await apiFetch('create_account', {
        method: 'POST',
        body: formData,
      });
      // Optionally re-check auth
      await checkAuth();
      navigate('/dashboard');
      return { success: true };
    } catch (error) {
      console.error('AuthContext: Signup failed:', error.message);
      return { success: false, error: error.message };
    }
  };

  // Log out
  const logout = async () => {
    try {
      await apiFetch('logout', {
        method: 'POST',
      });
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('AuthContext: Logout failed:', error.message);
      // Optionally show a toast or message
    }
  };

  // Resend email verification
  const resendVerificationEmail = async (email) => {
    try {
      const data = await apiFetch('resend_verification_email', {
        method: 'POST',
        body: { email },
      });
      return { success: true, message: data.message };
    } catch (error) {
      console.error('AuthContext: Resend verification failed:', error.message);
      return { success: false, error: error.message };
    }
  };

  // Memoize value
  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      loading,
      login,
      logout,
      signup,
      resendVerificationEmail,
    }),
    [user, loading]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
